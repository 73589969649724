//ドメイン名
const domainName = (new URL(window.location.href)).hostname

//cssファイル名取得
const getCssFileName = (fileName) => {
  return `/${domainName}/stylesheets/${fileName}`
}

//style linkタグを追加
const appendStyleLink = (fileName) => {
  let styleLink = document.createElement('link')
  styleLink.type = 'text/css'
  styleLink.rel = 'stylesheet'
  styleLink.href = fileName
  document.head.appendChild(styleLink)

  return styleLink
}

//CSSファイルをインポートする
export const importCssFile = (fileName) => {
  return appendStyleLink(getCssFileName(fileName))
}

//複数のCSSファイルをインポート
export const importCssFiles = (styleFileList) => {
  let styleLinkList = []

  styleFileList.forEach( styleFile => {
    styleLinkList.push(importCssFile(styleFile))
  })
  
  return styleLinkList
}

//CSSファイルをを削除
export const removeCssFiles = (styleLinkList) => {
  //追加したcssファイルを削除
  styleLinkList.forEach( styleLink => {
    document.head.removeChild(styleLink)
  })
}
