import axios from 'axios'
import { useStoreConfig } from '@/stores/config'
import { useStoreMessages } from '@/stores/messages'
import { getHtmlFileName } from '@/utils/htmlUtil'
import { useStoreUserInfo } from '@/stores/userInfo'
import router from '@/router'

//API URLを取得
const apiBaseUrl = () => {
  const config = useStoreConfig()
  return config.apiOriginName + '/'
}

//HTTPヘッダを作成
const httpHeaders = () => {
  const config = useStoreConfig()
  const userInfo = useStoreUserInfo()
  return {
    'X_DTW_Signature': config.apikey,
    'X_DTW_Token': userInfo.userToken
  }
}
//エラーハンドラ関数
const handleApiError = (error) => {
  const messages = useStoreMessages()
  messages.add({ 'error': 'データの取得ができませんでした。\nしばらくたってから再度操作をお願いします。' })

  if (error.response) {
    alert('API status: ' + error.response.status + ': ' + error)
    // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
    switch (error.response.data.error.code) {
      case 'A4010003':
      case 'A4010004':
      case 'A4010005':
        //認証エラー、ログイン画面へ
        router.push({ name: 'login' })
        break;
      default:
      //
    }
  } else if (error.request) {
    // リクエストは行われましたが、応答がありませんでした
  } else {
    // エラーをトリガーしたリクエストの設定中に何かが発生しました
  }
}

//htmlファイルのコンテンツを取得
export const getHtmlFile = async (fileName) => {
  const url = getHtmlFileName(fileName)
  try {
    const response = await fetch(url)
    return response.text()
  } catch (error) {
    handleApiError(error)
    throw error
  }
}

//APIを呼び出す
export const request = {
  //get処理(相対URL)
  async get(url, headers = httpHeaders()) {
    try {
      const response = await axios.get(apiBaseUrl() + url, { headers: headers })
      return response.data
    } catch (error) {
      handleApiError(error)
      throw error
    }
  },
  //get処理(完全なURL)
  async getFullUrl(url, headers = httpHeaders()) {
    try {
      const response = await axios.get(url, { headers: headers })
      return response.data
    } catch (error) {
      handleApiError(error)
      throw error
    }
  },
  //post処理
  async post(url, data, headers = httpHeaders()) {
    try {
      const response = await axios.post(apiBaseUrl() + url, data, { headers: headers })
      return response.data
    } catch (error) {
      handleApiError(error)
      throw error
    }
  }
}
