import { createRouter, createWebHistory } from 'vue-router'
import { useStoreConfig } from '@/stores/config'
import { useStoreUserInfo } from '@/stores/userInfo'
import { useStoreMessages } from '@/stores/messages'
import { getVueName } from '@/utils/vueUtil'

//ルーター定義
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/' + getVueName('welcome/WelcomePage.vue'))
  },
  {
    path: '/checkage',
    name: 'checkage',
    components: {
       window: () => import('../views/' + getVueName('user/CheckAgePage.vue'))
    },
    meta: { notRequiresAgeCheck: true }
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    components: {
      window: () => import('../views/' + getVueName('user/ForbiddenPage.vue')),
   },
    meta: { notRequiresAgeCheck: true }
  },
  {
    path: '/checkage_item',
    name: 'checkage_item',
    component: () => import('../views/' + getVueName('user/CheckAgeItemPage.vue')),
    meta: { notRequiresAgeCheck: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/' + getVueName('user/LoginPage.vue')),
  },
  {
    name: 'logout',
    path: '/logout',
    component: () => import('../views/' + getVueName('user/LogOutPage.vue')),
    meta: { notRequiresAgeCheck: true }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/' + getVueName('user/UserDetailPage.vue')),
    meta: { requiresAuth: true }
  },
  {
    path: '/user/edit',
    name: 'user_edit',
    component: () => import('../views/' + getVueName('user/UserChangePage.vue')),
    meta: { requiresAuth: true }
  },
  {
    path: '/user/registrations',
    name: 'user_registrations',
    component: () => import('../views/' + getVueName('registration/RegistrationMailTransPage.vue'))
  },
  {
    path: '/user/registrations/new',
    name: 'user_registrations_new',
    component: () => import('../views/' + getVueName('registration/RegistrationMailPage.vue'))
  },
  {
    path: '/user/registrations/:id/edit',
    name: 'user_registrations_uuid_edit',
    component: () => import('../views/' + getVueName('registration/RegistrationDetailPage.vue'))
  },
  {
    path: '/user',
    name: 'user_confirm',
    component: () => import('../views/' + getVueName('registration/RegistrationConfirmPage.vue'))
  },
  {
    path: '/user/done',
    name: 'user_done',
    component: () => import('../views/' + getVueName('registration/RegistrationCompletePage.vue'))
  },
  {
    path: '/lottery/onsales',
    name: 'lottery_onsales',
    component: () => import('../views/' + getVueName('lottery/LotteryIndexPage.vue')),
    props: { salesType: 1 },
  },
  {
    path: '/lottery/comings',
    name: 'lottery_comings',
    component: () => import('../views/' + getVueName('lottery/LotteryIndexPage.vue')),
    props: { salesType: 2 },
  },
  {
    path: '/lottery/archives',
    name: 'lottery_archives',
    component: () => import('../views/' + getVueName('lottery/LotteryIndexPage.vue')),
    props: { salesType: 3 },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/' + getVueName('news/NewsIndexPage.vue'))
  },
  {
    path: '/news/:id',
    name: 'news_detail',
    component: () => import('../views/' + getVueName('news/NewsDetailPage.vue'))
  },
  {
    path: '/purchases',
    name: 'purchases',
    component: () => import('../views/' + getVueName('purchases/IndexPage.vue')),
    meta: { requiresAuth: true }
  },
  {
    path: '/lotteries/:id',
    name: 'lotteries',
    component: () => import('../views/' + getVueName('lotteries/ShowPage.vue'))
  },
  {
    path: '/lotteries/:id/orders/new',
    name: 'lotteries_order',
    component: () => import('../views/' + getVueName('lotteries/orders/OrdersNew.Vue'))
  },
  {
    path: '/more_chan/new',
    name: 'more_chan_new',
    component: () => import('../views/' + getVueName('moreChan/MoreChanCreatePage.Vue')),
    meta: { requiresAuth: true }
  },
  {
    path: '/more_chan',
    name: 'more_chan',
    component: () => import('../views/' + getVueName('moreChan/MoreChanResultPage.Vue')),
    meta: { requiresAuth: true }
  },
  {
    path: '/page/notice',
    name: 'page_notice',
    component: () => import('../views/' + getVueName('pages/NoticePage.vue'))
  },
  {
    path: '/page/privacy',
    name: 'page_privacy',
    component: () => import('../views/' + getVueName('pages/PrivacyPage.vue'))
  },
  {
    path: '/page/agreement',
    name: 'page_agreement',
    component: () => import('../views/' + getVueName('pages/AgreementPage.vue'))
  },
  {
    path: '/page/faq',
    name: 'page_faq',
    component: () => import('../views/' + getVueName('pages/FaqPage.vue'))
  },
  {
    path: '/page/law',
    name: 'page_law',
    component: () => import('../views/' + getVueName('pages/LawPage.vue'))
  },
  {
    path: '/page/user_guide',
    name: 'page_user_guide',
    component: () => import('../views/' + getVueName('pages/UserGuidePage.vue'))
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/' + getVueName('pages/MaintenancePage.vue')),
    meta: { notRequiresAgeCheck: true }
  },
  {
    path: '/contacts/new',
    name: 'contacts_new',
    component: () => import('../views/' + getVueName('contacts/ContactsCreatePage.vue'))
  },
  {
    path: '/contacts/confirms',
    name: 'contacts_confirms',
    component: () => import('../views/' + getVueName('contacts/ContactsConfirmPage.vue'))
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/' + getVueName('contacts/ContactsResultPage.vue'))
  },
  {
    path: '/password/changes/new',
    name: 'password_changes_new',
    component: () => import('../views/' + getVueName('password/changes/PasswordForgetPage.vue'))
  },
  {
    path: '/password/changes',
    name: 'password_changes',
    component: () => import('../views/' + getVueName('password/changes/PasswordMailTransPage.vue'))
  },
  {
    path: '/password/edit',
    name: 'password_edit',
    component: () => import('../views/' + getVueName('passwords/PasswordEditPage.vue'))
  },
  {
    path: '/email/changes/new',
    name: 'email_changes_new',
    component: () => import('../views/' + getVueName('email/changes/EmailChangePage.vue'))
  },
  {
    path: '/email/changes',
    name: 'email_changes',
    component: () => import('../views/' + getVueName('email/changes/EmailTransPage.vue'))
  },
  { //デフォルト
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  }
]

//router作成
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) { //画面遷移時一番上へ
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

//画面遷移前の処理
router.beforeEach((to, from, next) => {

  const messages = useStoreMessages()
  const config = useStoreConfig()
  const userInfo = useStoreUserInfo()

  //メッセージ一覧をクリア
  messages.$reset()

  //メンテナンス中
  if (config.underMaintenance) {
    //メンテ画面にリダイレクトをする時、もう一回ここに来る
    if (to.name != 'maintenance') {
      next({ name: 'maintenance' })
      return
    }
    else {
      next()
      return
    }
  }

  //サイト年齢確認要、年齢未確認、年齢確認不要画面以外（年齢確認など）の場合、年齢確認へ
  if ((config.adultCheck && !userInfo.ageChecked)
    && !to.matched.some(record => record.meta.notRequiresAgeCheck)) {
    next({ name: 'checkage', query: { redirect: to.fullPath } })
    return
  }

  //login処理
  if (to.matched.some(record => record.meta.requiresAuth) && !userInfo.authenticated) {
    next({ name: 'login', query: { redirect: to.fullPath } })
    return
  }

  next()
})

export default router
