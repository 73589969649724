import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { importCssFiles } from '@/utils/cssUtil'
import { useStoreConfig } from '@/stores/config'
import { useStoreMessages } from '@/stores/messages'
import { getSystemSettings, getTenantSettings } from '@/services/configService'
import { createI18n } from "vue-i18n"
import translationsEN from '@/config/locales/en.yml'
import translationsJP from '@/config/locales/ja.yml'

//国際化対応
const i18n = new createI18n( {
  legacy: false,
  locale: 'jp',
  messages: {
    en: translationsEN, 
    jp: translationsJP
  }
})

//CSSファイルをインポート
importCssFiles([
  'application.css',
  'mobile.css',
  'form.css',
  'add_style.css'
])

//piniaを生成
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//appを生成
const app = createApp(App)
app.use(pinia)

const config = useStoreConfig()
const messages = useStoreMessages()

//グローバルエラーハンドラを設定
app.config.errorHandler = (err, vm, info) => {
  alert('Global error handler: ' + err)
  //エラーログを記録
  console.error('Global error handler: ', err, info);
  // エラー通知を表示
  messages.add({'error': 'エラーが発生しました。お問合せください。'})
}

// =========== 初期設定を行う BEGIN ===========
//ドメイン名をstoreに保持
config.setDomainName((new URL(window.location.href)).hostname)

try {
  //システム情報を取得し、storeに保持
  const systemSettings = await getSystemSettings()
  config.setSystemSettings(systemSettings)

  //テナント情報を取得し、storeに保持
  const tenantSettings = await getTenantSettings(config.tenantId)
  config.setTenantSettings(tenantSettings)

  //appを設定
  app.use(router)
  app.use(i18n)
  app.mount('#app')
}
catch {
  //初期設定にエラーが発生した場合、エラーページを表示
  window.location.href = window.location.origin + '/' + config.domainName +'/html/500.html'
}
// =========== 初期設定を行う END ===========
