<template>
  <div class="page_head"  v-if="pageHeadInfo.jp || pageHeadInfo.en">
    <div class="inner">
      <h1>{{ pageHeadInfo.jp }}</h1>
      <span class="eng">{{ pageHeadInfo.en }}</span>
    </div>
  </div>
</template>

<script setup>
import { useStorePageHeadInfo } from '@/stores/pageHeadInfo'
const pageHeadInfo = useStorePageHeadInfo()
</script>